import { get } from 'lodash';
import { twMerge } from 'tailwind-merge';

import { Controller, useFormContext, RegisterOptions, FieldValues, FieldPath } from 'react-hook-form';

import style from '../style.module.css';
import DateInput from './raw-input';

export interface InputDateContentProps<T extends FieldValues> {
  fieldName: FieldPath<T>;
  rules?: RegisterOptions;
  error?: string;
  id?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
}

export function InputDateContent<T extends FieldValues>({
  fieldName,
  rules,
  error,
  ...attrs
}: InputDateContentProps<T>) {
  const { id, className, disabled, required } = attrs;

  const {
    control,
    formState: { errors },
  } = useFormContext<T>();
  const fieldError = get(errors, fieldName)?.message as string | undefined;

  const focusClass = fieldError ? style.contentFocusOnError : style.contentFocus;

  const dynamicClasses = [focusClass, className].join(' ');

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { name, onChange, onBlur, value } }) => (
        <div>
          <DateInput
            id={id}
            name={name}
            label={''}
            value={value}
            required={(required as boolean) || false}
            error={fieldError}
            onBlur={onBlur}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            className={twMerge(style.content, dynamicClasses, 'h-full min-w-80 -top-4 -left-5 sm:rounded-pill')}
            disabled={disabled}
          />
        </div>
      )}
    />
  );
}
