import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import configuration from '@config';

import { UserInfo } from '@contexts/users/types';
import { IPricesProjectionPayload } from '@contexts/prices-projection/types';
import usePricesProjections from '@hooks/use-prices-projection';

import { EnergyTypeEnum } from '@src/utils/translators/proposal';

import SubmarketSection from '@components/molecules/upsert-prices-projection/submarket-section';
import { ProgressComponent } from '@components/atoms/progress';
import ButtonComponent from '@components/atoms/button';
import ContractConditionsSection from '@components/molecules/upsert-prices-projection/contract-conditions-section';

import {
  parsePricesProjectionPayload,
  submarkets,
  unparsePricesProjection,
  pricesProjectionFormDefaultValues,
  UpsertPricesProjectionFormSteps,
} from './helper';
import { validatePricesProjectionPayload } from './validation';

interface IFormUpsertPricesProjection {
  user: UserInfo;
  onFormSuccess: () => void;
  onFormFailure: () => void;
  setLayoutTitle: (title: string) => void;
  setOpenConditionsOfSigningsModal: (open: boolean) => void;
}

export function FormUpsertPricesProjection({
  user,
  onFormSuccess,
  onFormFailure,
  setLayoutTitle,
  setOpenConditionsOfSigningsModal,
}: IFormUpsertPricesProjection) {
  const { pricesProjectionById, setPricesProjectionId, createPricesProjectionHandler } = usePricesProjections();
  const { id: pricesProjectionId } = useParams();

  const methods = useForm<IPricesProjectionPayload>({
    defaultValues: pricesProjectionById
      ? unparsePricesProjection(pricesProjectionById)
      : pricesProjectionFormDefaultValues,
  });
  const { setError, handleSubmit, reset } = methods;

  const [loading, setLoading] = useState(false);

  const [step, setStep] = useState<number>(1);

  const navigate = useNavigate();
  const nextStep = () => setStep(step + 1);
  const prevStep = () => {
    if (step === 1) navigate(-1);
    else setStep(step - 1);
  };

  const onSubmit = (values: IPricesProjectionPayload) => {
    setLoading(true);

    const isValidDate = validatePricesProjectionPayload(values, setError, setLoading);
    if (!isValidDate) return;

    const parsedPricesProjectionPayload = parsePricesProjectionPayload(values, user, configuration.MAIN_PARTNER_ID);
    createPricesProjectionHandler(parsedPricesProjectionPayload, pricesProjectionId)
      .then((response) => {
        if (response.data.id) {
          onFormSuccess();
        } else {
          onFormFailure();
        }
      })
      .catch(() => onFormFailure());

    setLoading(false);
  };

  React.useEffect(() => {
    if (pricesProjectionId) setPricesProjectionId(pricesProjectionId);
  }, [pricesProjectionId]);

  React.useEffect(() => {
    if (pricesProjectionById && step === 1) {
      reset(unparsePricesProjection(pricesProjectionById));
    }
  }, [pricesProjectionById]);

  const currentStep = UpsertPricesProjectionFormSteps[step - 1];
  React.useEffect(() => {
    const layoutTitle = currentStep ? currentStep.title : 'Energia incentivada';
    setLayoutTitle(layoutTitle);
  }, [step]);

  return (
    <FormProvider {...methods}>
      <form className="flex flex-col w-full" onSubmit={handleSubmit(onSubmit)}>
        {step === 1 || step === 2 ? (
          <div className="flex flex-col w-full gap-7 mb-10">
            {submarkets.map((submarket) => (
              <SubmarketSection
                key={`submarket-form-${submarket.name}-${currentStep.energyType}`}
                submarket={submarket}
                description={currentStep.description}
                isRequired={false}
                energyType={currentStep.energyType as keyof typeof EnergyTypeEnum}
              />
            ))}
          </div>
        ) : (
          <div>
            <div className="my-5">
              <ContractConditionsSection
                title={'Condições de contratação*'}
                description={'Dados sobre as condições comerciais de contratação de energia.*'}
                isRequired={true}
              />
            </div>
            <label className="text-paragraph-medium font-normal text-neutral-50">
              *As premissas estabelecidas seguem as condições comerciais de contratação,
              <a href="#" onClick={() => setOpenConditionsOfSigningsModal(true)} className="text-green-500">
                {' '}
                acesse-as por aqui.
              </a>
            </label>
          </div>
        )}
        <div className="flex w-full justify-between items-center my-10 mr-20">
          <div className="flex gap-4 text-paragraph-medium font-normal">
            {step === 3 ? (
              <ButtonComponent
                key="form-upsert-prices-submit-button"
                kind="primary"
                label="Enviar"
                type="submit"
                loading={loading}
                disabled={loading}
              />
            ) : (
              <ButtonComponent
                key="form-upsert-prices-next-step-button"
                kind="primary"
                label="Avançar"
                type="button"
                onClick={nextStep}
              />
            )}
            <ButtonComponent
              key="form-upsert-prices-prev-step-button"
              kind="secondary"
              label={step === 1 ? ' Cancelar' : 'Voltar'}
              type="button"
              onClick={prevStep}
              disabled={loading}
            />
          </div>
          <div className="w-1/3">
            <ProgressComponent.Root>
              <ProgressComponent.Bar size="large" progressValue={currentStep.progressValue} />
            </ProgressComponent.Root>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
