import moment from 'moment';

import { EnergyTypeEnum, SubmarketEnum } from '@src/utils/translators/proposal';

import { IPricesProjection, IPricesProjectionPayload, ISubmarketPricesProjection } from '@contexts/prices-projection';
import { UserInfo } from '@contexts/users/types';

export interface SubmarketIndexer {
  name: keyof ISubmarketPricesProjection;
  label: string;
  type: SubmarketEnum;
}

export const submarkets: SubmarketIndexer[] = [
  {
    name: 'midwestSoutheastSubmarket',
    label: 'Sudeste / Centro Oeste',
    type: SubmarketEnum.SOUTHEAST,
  },
  {
    name: 'southSubmarket',
    label: 'Sul',
    type: SubmarketEnum.SOUTH,
  },
  {
    name: 'northeastSubmarket',
    label: 'Nordeste',
    type: SubmarketEnum.NORTHEAST,
  },
  {
    name: 'northSubmarket',
    label: 'Norte',
    type: SubmarketEnum.NORTH,
  },
];

export const pricesProjectionFormDefaultValues: IPricesProjectionPayload = {
  retailTraderId: null,
  user: {
    id: '',
    name: '',
    pictureUrl: '',
  },
  charges: null,
  expirationDate: null,
  submarkets: {
    northeastSubmarket: {
      i5: {},
      i100: {},
    },
    northSubmarket: {
      i5: {},
      i100: {},
    },
    midwestSoutheastSubmarket: {
      i5: {},
      i100: {},
    },
    southSubmarket: {
      i5: {},
      i100: {},
    },
  },
};

interface UpsertPricesProjectionFormStepProps {
  id: number;
  title: string;
  description: string;
  energyType?: keyof typeof EnergyTypeEnum;
  progressValue: number;
}

export const UpsertPricesProjectionFormSteps: UpsertPricesProjectionFormStepProps[] = [
  {
    id: 1,
    title: 'Energia 50% incentivada',
    description: 'Preços de energia 50% incentivada conforme o período neste submercado.',
    energyType: 'INCENTIVIZED_50',
    progressValue: 0,
  },
  {
    id: 2,
    title: 'Energia 100% incentivada',
    description: 'Preços de energia 100% incentivada conforme o período neste submercado.',
    energyType: 'INCENTIVIZED_100',
    progressValue: 50,
  },
  {
    id: 3,
    title: 'Encargos e Validade',
    description: 'Preços de energia 100% incentivada conforme o período neste submercado.',
    progressValue: 100,
  },
];

export const energyTypes = {
  i5: 'I5 - Incentivada 50%',
  i100: 'I100 - Incentivada 100%',
};

export const projectionYears = (): Array<number> => {
  const currentDate = moment();
  const minimalMigrationDate = currentDate.add(220, 'days');
  const initialYear = minimalMigrationDate.get('year');

  const yearsInterval = 8;

  const projectionYears = Array(yearsInterval)
    .fill(initialYear)
    .map((_, index) => initialYear + index);

  return projectionYears;
};

const expirationDateParser = (expirationDate: string) => {
  return moment(expirationDate, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
};

const expirationDateUnparser = (expirationDate: string | null) => {
  return moment(expirationDate, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
};

export function unparsePricesProjection(projection: IPricesProjection) {
  const expirationDate = expirationDateUnparser(projection.expirationDate);
  return { ...projection, expirationDate };
}

export function parsePricesProjectionPayload(
  values: IPricesProjectionPayload,
  user: UserInfo,
  traderId: string,
): IPricesProjectionPayload {
  const expirationDate = expirationDateParser(values.expirationDate as string);
  const parsedPayload: IPricesProjectionPayload = {
    expirationDate: expirationDate,
    submarkets: values.submarkets,
    retailTraderId: traderId,
    charges: values.charges,
    user: {
      id: user.id,
      name: user.name,
      pictureUrl: user.pictureUrl,
    },
  };

  return parsedPayload;
}
