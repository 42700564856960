import Skeleton from 'react-loading-skeleton';

export function UpserPricesProjectionPageSkeelton() {
  return (
    <div className="flex flex-col gap-10 w-full">
      {Array(4)
        .fill('')
        .map(() => (
          <div className="w-full flex gap-12 justify-between">
            <div className="flex flex-col gap-4 w-1/5">
              <Skeleton className="py-2" />
              <Skeleton className="py-6" />
            </div>
            <div className="flex justify-between items-center w-3/4">
              {Array(5)
                .fill('')
                .map(() => (
                  <div className="w-40">
                    <Skeleton className="py-6 rounded-full" />
                  </div>
                ))}
            </div>
          </div>
        ))}
    </div>
  );
}
